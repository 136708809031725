<script>
  export default {
    name: 'quest-result',
    data: function () {
      return { 
      }
    },
    computed: {
      structuresWithNotices() {
        return this.$store.state.session.questNotices
      }
    },
    created() {
      this.$store.dispatch("pushPage", "quest-result")

      if (!(["quest-submission"]).includes(this.$store.getters.previousPage) ) {
        this.$router.replace({name: "home"})
      } else {
        this.$store.dispatch("disableBackButton")
      }
    },
    methods: {
      retakeQuestionnaire() {
        this.$store.dispatch("setQuestionnaireState", "load_questions")
      }
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-5-desktop">
          <div class="block">
            <div class="notification is-success is-light has-text-centered">
              <div class=" p-5">
                <span class="icon has-text-success is-large ">
                  <i class="fas fa-3x fa-check"></i>
                </span>
                <p class="is-size-4">{{ $t("successMessage") }}</p>
              </div>
            </div>
          </div>

          <div class="block">
            <p>
              {{ $t("message") }}
            </p>
          </div>
          <div class="my-bordered p-4 block" v-if="structuresWithNotices.length > 0">
            <div class="block"
              v-for="(struct, index) in structuresWithNotices"
              v-bind:key="index">
              <p class=" has-text-centered title is-4">
                {{ $t("infoTitle", {structureName: struct.structure_name}) }}
              </p>
              <div class="notification is-light mb-1" 
                v-for="(notice, index2) in struct.notice"
                :key="index2">                    
                {{ notice["it"] }}  
              </div>
            </div>
          </div>

          <div class="block">
            <article class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message2") }}
              </div>
            </article>
            <article class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message3") }}
              </div>
            </article>
          </div>

          <div class="block">
            <button class="button is-medium is-fullwidth mb-1 my-primary-button" v-on:click="retakeQuestionnaire()">
              {{ $t("compileAgain") }}
            </button>
            <button class="button is-medium is-fullwidth my-primary-button" v-on:click="$router.replace({name: 'home'})">
              {{ $t("home") }}
            </button>
          </div>
        </div>
      </div>  
    </div> 
  </div>
</template>

<i18n>
{
  "it": {
    "successMessage": "Questionario compilato",
    "message": "È stata inviata una e-mail all'indirizzo da te specificato. Troverai il codice da presentare per accedere ai luoghi da te scelti.",
    "message2": "La compilazione del questionario è necessaria anche per eventuali accompagnatori o tutori.",
    "message3": "Se sei un accompagnatore o un tutore, clicca su ‘Compila nuovo questionario’ e seleziona il tuo indirizzo mail.",
    
    "memoValidity": "Ricordati che la validità del questionario cambia in base ai luoghi in cui devi recarti.",
    "infoTitle": "Avvisi da parte di {structureName}",
    "compileAgain": "Compila nuovo questionario",
    "home": "Torna alla home"
  }
}
</i18n>

<style>
</style>