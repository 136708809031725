<script>
  import { extractQuestionAnswerPairs } from "@/questionnaire"
  import VueRecaptcha from "vue-recaptcha"
  import EventBus from "@/eventbus"

  export default {
    name: "quest-summary",
    data: function () {
      return {
        isVisibleSummary: false,
        notes: this.$store.state.session.questNotes,
        captchaResponse: ""
      }
    },
    components: {VueRecaptcha},
    props: ["screenWidth"],
    created() {
      this.$store.dispatch("pushPage", "quest-summary")
      if (!(["quest-question"]).includes(this.$store.getters.previousPage)) {
        this.$router.replace({name: "home"})
      } else {
        this.$store.dispatch("setBackLogic", () => {
          this.$store.dispatch("backToPreviousQuestion")
          this.$store.dispatch("setQuestionnaireState", "question")
        })
      }
    },
    computed: {
      mailAddress() {
        return this.$store.state.session.questMail
      },
      structures() {
        return this.$store.state.session.questStructures
      },
      questionsTexts() {
        return extractQuestionAnswerPairs(this.$store.state.session.questQuestions, this.$store.state.session.questAnswers)
      },
      mobileMode() {
        return this.screenWidth <= 768
      }
    },
    methods: {
      toggleSummarySubView() {
        this.isVisibleSummary = !this.isVisibleSummary
      },
      onSubmit() {
        this.$store.dispatch("setNotes", this.notes)
        this.$store.dispatch("setQuestionnaireCaptcha", this.captchaResponse)
        this.$store.dispatch("setQuestionnaireState", "submission")
      },
      isConsentEnabled(structure_id, consent) {
        return this.$store.state.session.questStructuresConsents.find(elem => elem.structure_id == structure_id).consents.includes(consent)
      },
      verifyCaptcha(response) {
        this.captchaResponse = response
      },
			expireCaptcha() {
				this.$refs.questSubmitRecaptcha.reset()
        this.captchaResponse = ""
			},
      errorCaptcha() {
        EventBus.$emit("errorMessage", "Errore di caricamento del componente. Verifica di essere connesso ad internet")
      }
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-5-desktop">
          <button
            v-if="mobileMode" class="button is-medium is-fullwidth"
            @click="toggleSummarySubView"
          >
            {{ isVisibleSummary ? $t("hideRecap") : $t("showRecap") }}
          </button>
          <div v-if="!mobileMode || isVisibleSummary">
          <div class="block" v-if="!mobileMode">
            <h1 class="title is-3">
              {{ $t("title") }}
            </h1>
          </div>

          <div v-if="!mobileMode">
            <article class="message is-warning mb-1">
              <div class="message-body">
                {{ $t("message") }}
              </div>
            </article>
          </div>

          <div class="block">
            <p> {{ $t("email") }}:</p>
            <label class="is-size-4"> {{ this.mailAddress }} </label>
          </div>

          <div class="block content">
            <p class="m-0 p-0">{{ $t("selStructures") }}: </p>
            <ul class="mt-0 ml-4">
              <li  v-for="(struct, index) in this.structures"
                v-bind:key="index">
                <p>
                  <label class="is-size-4">
                    {{ struct.name }}
                  </label>

                  (
                  <label v-if="isConsentEnabled(struct.id, 'access')">
                    {{ $t("authText1") }}
                  </label>
                  <label  v-if="isConsentEnabled(struct.id, 'acquisition')">
                    {{ $t("authText2") }}
                  </label>
                  <label  v-if="isConsentEnabled(struct.id, 'archive')">
                    {{ $t("authText3") }}
                  </label>
                  )
                </p>
              </li>
            </ul>
          </div>
          <div class="block">

            <p class="m-0 p-0">{{ $t("summaryLabel") }}: </p>
            <div class="has-background-white-ter notification mt-0 p-3 mb-1 pl-5"
              v-for="(quest, index) in questionsTexts"
              v-bind:key="index">
              <p class="is-italic">
                {{ quest.question }}
              </p>
              <p class="is-size-5">
                {{ quest.answer }}
              </p>
            </div>
          </div>
          </div>

          <div class="block mt-4">
            <div class="message is-warning">
              <div class="message-body">
                {{ $t("message3") }}
              </div>
            </div>
            <div class="field">
              <p>{{ $t("notes") }}:</p>
              <div class="control">
                <input class="input" type="text" v-model="notes">
              </div>
            </div>
          </div>

          <div class="block">
            <div class="level">
              <div class="level-item">
                <vue-recaptcha sitekey="6LfNvQcaAAAAACSu-EOmV_MAJxz8LyY19hkDi1ae"
                  v-on:verify="verifyCaptcha"
                  v-on:expired="expireCaptcha"
                  v-on:error="errorCaptcha"
                  :loadRecaptchaScript="true"
                  ref="questSubmitRecaptcha"></vue-recaptcha>
              </div>
            </div>
          </div>

          <button class="button is-medium is-fullwidth my-primary-button" v-on:click="onSubmit()">
            {{ $t("confirm") }}
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "it": {
    "title": "Riepilogo",
    "message": "Verifica i dati inseriti e concludi la compilazione.",
    "message3": "Aggiungi un titolo per distinguere questo questionario dai prossimi che compilerai. Il titolo non viene salvato da TRAC19.",
    "notes": "Titolo (opzionale)",
    "email": "E-mail",
    "summaryLabel": "Riepilogo",
    "selStructures": "Valido per l'accesso presso",
    "authText1": "lettura del codice all’accesso in struttura",
    "authText2": ", acquisizione dati in cartella clinica",
    "authText3": ", costruzione banca dati anonima",
    "questionsTitle": "Domande e risposte",
    "showRecap": "Mostra riepilogo",
    "hideRecap": "Nascondi riepilogo",
    "confirm": "Invia questionario"
  }
}
</i18n>

<style>
</style>
