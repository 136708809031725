<script>
  import api from "@/api"
  import MyQuestStructure from "@/components/MyQuestStructure"

  var debounce = require("debounce")

  export default {
    name: "quest-structures",
    data: function() {
      return {
        searchQuery: "",
        unselectedStructures: []
      }
    },
    props : ["screenWidth"],
    components: {
      MyQuestStructure
    },
    computed: {
      selectedStructures() {
        return this.$store.state.session.questStructures
      }
    },
    created() {
      this.$store.dispatch("pushPage", "quest-structures")
      if (!(["home", "quest-result", "quest-structures-list", "quest-structures-auth"]).includes(this.$store.getters.previousPage)) {
        this.$router.replace({name: "home"})
      } else {
        this.$store.dispatch("setBackLogic", () => {
          this.$router.replace({name: "home"})
        })
      }
    },
    methods: {
      searchStructure() {
        if (this.searchQuery.length > 0) {
          api.queryStructures(this.searchQuery).then(response => {
            this.unselectedStructures.splice(0, this.unselectedStructures.length)
            for (var structure of response.data.results){
              structure.isDisabled = this.searchDuplicatedStructures(this.selectedStructures, structure) != -1
              structure.expires = this.computeExpirationDate(structure)
              this.unselectedStructures.push(structure)
            }
          }).catch(() => {})
        } else {
          this.unselectedStructures = []
        }
      },
      debounceInput: debounce(function() { this.searchStructure() }, 500),

      addStructure(structIdx) {
        if (!this.unselectedStructures[structIdx].isDisabled) {
          this.selectedStructures.push(this.unselectedStructures[structIdx])
          this.unselectedStructures[structIdx].isDisabled = true
        }
      },
      removeStructure(structIdx) {
        var structure = this.selectedStructures.splice(structIdx, 1)[0]

        var unselectedStructureIdx = this.unselectedStructures.findIndex(elem => elem.id == structure.id)
        if (unselectedStructureIdx != -1) {
          this.unselectedStructures[unselectedStructureIdx].isDisabled = false
        }
      },

      computeExpirationDate(structure) {
        const expires = new Date()
        expires.setDate(expires.getDate() + structure.form_validity_days - 1)
        return expires
      },
      searchDuplicatedStructures(sourceList, target) {
        return sourceList.findIndex(elem => elem.name == target.name)
      },

      goToNextView() {
        this.$store.dispatch("submitQuestStructures", this.selectedStructures)
        this.$store.dispatch("setQuestionnaireState", "structures_consents")
      },
      showStructures() {
        this.$store.dispatch("setQuestionnaireState", "structures_list")
      }
    }
  }

</script>

<template>
  <div class="section">
    <div class="columns is-centered">
      <div class="column is-5-desktop">
        <div class="block">
          <h1 class="title is-3">
            {{ $t("title") }}
          </h1>
        </div>

        <div class="block">
          <div class="message is-info mb-1">
            <div class="message-body">
              {{ $t("message1") }}
            </div>
          </div>
        </div>

        <div class="block" v-if="selectedStructures.length > 0">
          <p>{{ $t("selStructures") }}</p>
          <MyQuestStructure
            v-for="(structure, index) in selectedStructures"
            v-bind:key="index"
            v-bind:selected="true"
            v-bind:structure="structure"
            v-on:click.native="removeStructure(index)"/>
        </div>

        <div class="block">
          <div class="field mb-0" v-if="screenWidth >1024">
            <div class="control has-icons-right">
              <input class="input is-medium" type="text" placeholder="Cerca struttura" v-model="searchQuery" v-on:input="debounceInput">
              <span class="icon is-small is-right">
                <i class="fas fa-search"></i>
              </span>
            </div>
          </div>
          <div class="field has-addons mb-0" v-else>
            <div class="control is-expanded">
              <input class="input is-medium" type="text" placeholder="Cerca struttura" v-model="searchQuery">
            </div>
            <div class="control" v-if="screenWidth < 1024">
              <button class="button is-info is-medium" v-on:click="searchStructure">
                <span class="icon is-medium">
                  <i class="fas fa-search"></i>
                </span>
                <span v-if="screenWidth > 768 ">
                  {{ $t("search") }}
                </span>
              </button>
            </div>
          </div>

          <div class="is-size-6 is-italic" v-if="screenWidth <= 1024">
            {{ $t("mobileSearch") }}
          </div>

          <div v-if="unselectedStructures.length > 0">
            <MyQuestStructure
              v-for="(structure, index) in unselectedStructures"
              v-bind:key="index"
              v-bind:unselected="structure.isDisabled ? false : true"
              v-bind:disabled="structure.isDisabled"
              v-bind:structure="structure"
              v-on:click.native="addStructure(index, false)" />
          </div>
        </div>

        <div class="block">
          <a class="button is-medium is-fullwidth"
            v-bind:class="{'is-static': selectedStructures.length==0, 'my-primary-button': selectedStructures.length!=0}" v-on:click="goToNextView()">
            {{ $t("continue") }}
          </a>
        </div>

        <div class="block">
          <article class="message is-info mb-1">
            <div class="message-body">
              {{ $t("info") }} <a v-on:click="showStructures"> {{ $t("infoButton") }} </a>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "it": {
    "title": "Seleziona il luogo in cui vuoi recarti",
    "message1": "Scegli uno o più luoghi: il tuo questionario sarà valido per tutte le strutture selezionate.",
    "mobileSearch": "Digita la struttura, poi clicca sulla lente a destra",
    "info": "Non riesci a trovare il luogo? ",
    "infoButton": "Clicca qui",
    "warning": "Ricordati di compilare il questionario nei tempi indicati dalla struttura in cui vuoi recarti.",
    "oldSelStructures": "Luoghi già registrati (non possono essere modificati)",
    "selStructures": "Luoghi selezionati",
    "loading": "Caricamento",
    "continue": "Continua",
    "search": "Cerca"
  }
}
</i18n>

<style>

</style>
