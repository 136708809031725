<script>
  import EventBus from "@/eventbus"
  import { extractAnswerSet } from "@/questionnaire"
  import api from "@/api"

  export default {
    name: "quest-submission",
    created() {
      this.$store.dispatch("pushPage", "quest-submission")

      if (!(["quest-summary"]).includes(this.$store.getters.previousPage)) {
        this.$router.replace({name: "home"})
      } else {
        this.$store.dispatch("disableBackButton")
        api.submitQuestionnaire(this.formatQuestionnaireData(), this.$store.state.session.captchaCode)
        .then((response) => {
          this.$store.dispatch("partiallyResetQuestionnaireState")
          if (response.status == 200) {
            this.$store.dispatch("setQuestionnaireNotices", response.data.notices)
            this.$store.dispatch("setQuestionnaireState", "result")
          } else {
            EventBus.$emit("errorMessage", "Impossibile inviare il questionario")
            this.$router.replace({name: "home"})
          }
        }).catch(() => {
          EventBus.$emit("errorMessage", "Impossibile inviare il questionario")
          this.$router.replace({name: "home"})
        })
      }
      
    },
    methods: {
      formatQuestionnaireData() {
        return {
          note: this.$store.state.session.questNotes,
          language: this.$store.state.session.language,
          recipients: this.$store.state.session.questStructuresConsents,
          form_model_id: this.$store.state.session.questModel,
          answers: extractAnswerSet(this.$store.state.session.questQuestions, this.$store.state.session.questAnswers),
          analytics: this.$store.state.session.questAnalytics,
          address: this.$store.state.session.questMail
        }
      }
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-4-desktop is-6-tablet">
          <div class="box is-flex is-flex-direction-column is-align-items-center section">
            <div class="block has-text-centered">
              <h1 class="title is-3">
                {{ $t("loadingTitle") }}
              </h1>
              <h2 class="subtitle is-5">
                {{ $t("loadingSubtitle") }}
              </h2>
            </div>
            <div class="loader is-loading my-loader"></div>
          </div>
        </div>
      </div>  
    </div>
  </div> 
</template>

<i18n>
{
  "it": {
    "loadingTitle": "Invio del questionario",
    "loadingSubtitle": "L'operazione dovrebbe richiedere qualche secondo"
  }
}
</i18n>

<style>
</style>