<script>
  import api from"@/api"
  import EventBus from "@/eventbus"

  export default {
    name: 'quest-structures-auth',
    components: {},
    data: function() {
      return {
        structuresConsents: [],
        authCount: 0,
        privacyCheck: false,
      }
    },
    props: {
      screenWidth: Number
    },
    computed: {
      structures() {
        return this.$store.state.session.questStructures
      },
      disableSubmission() {
        for (var sAuth of this.structuresConsents)
          if (this.privacyCheck==false ||
              sAuth.consents.access != "yes" ||
              sAuth.consents.acquisition == "" ||
              sAuth.consents.archive == "")
            return true
        return false
      }
    },
    created() {
      this.$store.dispatch("pushPage", "quest-structures-auth")
      if (!(["quest-structures", "quest-mail", "quest-mail-add"]).includes(this.$store.getters.previousPage)) {
        this.$router.replace({name: "home"})
      } else if (this.$store.state.session.questStructures.length == 0) {
        this.$router.replace({name: "home"})
      } else {
        this.$store.commit("setBackLogic", () => {
          this.$store.dispatch("setQuestionnaireState", "structures")
        })
      }

      for (var struct of this.structures) {
        this.structuresConsents.push({
          "structure_id": struct.id,
          "consents": {
            "access": "",
            "acquisition": "",
            "archive": "",
          }
        })
      }
    },
    mounted() {

    },
    methods: {
      getStructure(structId) {
        return this.structuresConsents.find(elem => elem.structure_id == structId)
      },
      onSubmit() {
        var consents = []
        for (var elem of this.structuresConsents) {
          var tempArray = []
          for (const [key, value] of Object.entries(elem.consents))
            if (value == "yes")
              tempArray.push(key)
          consents.push({
            "consents": tempArray,
            "structure_id": elem.structure_id
          })
        }
        this.$store.dispatch("submitQuestStructuresConsents", consents)

        api.getMails().then((response) => {
          if (response.data.addresses.length == 0)
            this.$store.dispatch("setQuestionnaireState", "mail_add")
          else
            this.$store.dispatch("setQuestionnaireState", "mails")
        }).catch(() => {
          EventBus.$emit("errorMessage", "Impossibile accedere alle e-mail associate")
          this.$router.replace({name: "home"})
        })
      }
    },
  }

</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-5-desktop">
          <div class="block">
            <div class="message is-info mb-1">
              <div class="message-body">
                {{ $t("message1") }}
              </div>
            </div>
            <div class="message is-warning mb-1">
              <div class="message-body">
                {{ $t("message2") }}
              </div>
            </div>
          </div>

          <div class="block">
            <form v-on:submit.prevent="onSubmit()">
              <div class="my-bordered p-5 block is-fullwidth"
                v-for="(struct, index) in structures"
                v-bind:key="index">

                <div class="is-size-4 mb-5 has-text-centered">
                  {{ struct.name }}
                </div>

                <div class="block">
                  <div class="columns is-mobile is-multiline has-text-centered-mobile is-vcentered my-3 ">
                    <div class="column py-0 is-12-mobile">
                      <p class="is-size-5">
                        <span class="has-text-weight-bold"> [{{ $t("requiredAuthorization") }}] </span>
                        {{ $t("authTitle1") }}
                      </p>
                      <p class="help is-danger mt-0 mb-0" v-if="structuresConsents[index].consents['access'] == 'no'">
                          {{ $t("requiredAuthHelper") }}
                      </p>
                    </div>
                    <div class="column py-0 is-narrow is-12-mobile">
                      <div class="field has-addons">
                        <p class="control" :class="{'is-expanded': screenWidth <= 768}">
                          <a class="button is-fullwidth box" :class="{'is-success is-light': getStructure(struct.id).consents.access=='yes'}"
                            @click="() => getStructure(struct.id).consents.access='yes'">
                            <span> Accetto </span>
                          </a>
                        </p>
                        <p class="control" :class="{'is-expanded': screenWidth <= 768}">
                          <a class="button is-fullwidth box" :class="{'is-danger is-light': getStructure(struct.id).consents.access=='no'}"
                            @click="() => getStructure(struct.id).consents.access='no'">
                            <span> Rifiuto </span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 mt-0 has-background-white-ter">
                    <p class="has-text-justified p-1">
                      {{ $t("authText1") }}
                    </p>
                  </div>
                </div>

                <div class="block">
                  <div class="columns is-mobile is-multiline has-text-centered-mobile is-vcentered my-3 ">
                    <div class="column py-0 is-12-mobile">
                      <p class="is-size-5">
                        {{ $t("authTitle2") }}
                      </p>
                    </div>
                    <div class="column py-0 is-narrow is-12-mobile">
                      <div class="field has-addons">
                        <p class="control" :class="{'is-expanded': screenWidth <= 768}">
                          <a class="button is-fullwidth box" :class="{'is-success is-light': getStructure(struct.id).consents.acquisition=='yes'}"
                            @click="() => getStructure(struct.id).consents.acquisition='yes'">
                            <span> Accetto </span>
                          </a>
                        </p>
                        <p class="control" :class="{'is-expanded': screenWidth <= 768}">
                          <a class="button is-fullwidth box" :class="{'is-danger is-light': getStructure(struct.id).consents.acquisition=='no'}"
                            @click="() => getStructure(struct.id).consents.acquisition='no'">
                            <span> Rifiuto </span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 mt-0 has-background-white-ter">
                    <p class="has-text-justified p-2">
                      {{ $t("authText2") }}
                    </p>
                  </div>
                </div>

                <div class="block">
                  <div class="columns is-mobile is-multiline has-text-centered-mobile is-vcentered my-3 ">
                    <div class="column py-0 is-12-mobile">
                      <p class="is-size-5">
                        {{ $t("authTitle3") }}
                      </p>
                    </div>
                    <div class="column py-0 is-narrow is-12-mobile">
                      <div class="field has-addons">
                        <p class="control" :class="{'is-expanded': screenWidth <= 768}">
                          <a class="button is-fullwidth box" :class="{'is-success is-light': getStructure(struct.id).consents.archive=='yes'}"
                            @click="() => getStructure(struct.id).consents.archive='yes'">
                            <span> Accetto </span>
                          </a>
                        </p>
                        <p class="control" :class="{'is-expanded': screenWidth <= 768}">
                          <a class="button is-fullwidth box" :class="{'is-danger is-light': getStructure(struct.id).consents.archive=='no'}"
                            @click="() => getStructure(struct.id).consents.archive='no'">
                            <span> Rifiuto </span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 mt-0 has-background-white-ter">
                    <p class="has-text-justified p-1">
                      {{ $t("authText3", {structureName: struct.name}) }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="block">
                <article class="message is-info">
                  <div class="message-body">
                    {{ $t("message3") }} <a href="https://google.com"  target="_blank">{{ $t("privacyLink") }}</a>
                  </div>
                </article>
              </div>

              <div class="block">
              <section>
                <b-field>
                    <b-checkbox type="is-info" v-model="privacyCheck">{{$t("privacyCheck")}}</b-checkbox>
                </b-field>
              </section>
            </div>

              <button class="button is-medium is-fullwidth is-disable my-primary-button"
                v-bind:class="{'is-static': disableSubmission, 'my-primary-button': !disableSubmission.length!=0}">
                {{ $t("continue") }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "it": {
    "info": "Per assicurarti un servizio rapido e in completa sicurezza, le strutture hanno bisogno del tuo consenso al trattamento dei dati. Il consenso viene richiesto unicamente per le finalità di seguito indicate. Ricordati che potrai modificare le autorizzazioni al trattamento in qualsiasi momento.",
    "message1": "Parliamo di privacy: leggi perché trattiamo i dati e scegli quali autorizzazioni fornire.",
    "message2": "Nell'informativa sotto riportata puoi leggere come modificare le autorizzazioni in qualsiasi momento.",
    "requiredAuthorization": "Obbligatorio",
    "requiredAuthHelper": "Questo consenso è obbligatorio.",
    "authTitle1": "Accesso alla struttura",
    "authText1": "Voglio consentire alla struttura di visualizzare le risposte al questionario tramite la lettura del QR code che io stesso fornirò.",
    "authTitle2": "Acquisizione dati",
    "authText2": "Voglio permettere alla struttura di acquisire le risposte del mio questionario e di trasferirle nel sistema informativo della struttura collegando le informazioni alla mia anagrafica.",
    "authTitle3": "Costruzione Banca Dati Anonima",
    "authText3": "Grazie al mio consenso, sono consapevole che i miei dati saranno resi anonimi e verranno utilizzati per la costruzione di una Banca Dati Anonima, che supporterà il miglioramento di TRAC19 e fornirà un prezioso contributo alla ricerca scientifica.",
    "message3": "Per saperne di più, consulta la ",
    "privacyLink": "Informativa Privacy",
    "privacyCheck": "Dichiaro di aver preso visione dell’Informativa Privacy",
    "continue": "Continua"
  }
}
</i18n>

<style>

</style>
