<template>
  <div class="columns is-mobile is-vcentered box my-bordered is-marginless p-1"
    v-bind:class="{'is-clickable': !disabled,
                   'has-background-white-ter': disabled,
                   'has-text-grey': disabled}">
    <div class="column">
      <div v-bind:class="{'has-text-grey': disabled}">
        <p class="is-size-4">{{ structure.name }}</p>
        <p>{{ structure.address }}</p>
        <p>{{ $t("validUntil") }} <strong>{{ structure.expires.toLocaleDateString("it-IT") }}</strong></p>
      </div>
    </div>
    <div class="column is-narrow">
      <span v-if="selected" class="icon has-text-danger">
        <i class="fas fa-minus"></i>
      </span>
      <span v-if="unselected" class="icon has-text-success">
        <i class="fas fa-plus"></i>
      </span>
    </div>
  </div>
</template>

<script>

  export default {
    name: "quest_location_structure",
    props: {
      selected: Boolean,
      unselected: Boolean,
      disabled: Boolean,
      structure: Object,
    }
  }

</script>

<i18n>
{
  "it": {
    "validUntil": "Questionario valido fino al:"
  }
}
</i18n>
