<script>
  import api from "@/api"
  import EventBus from "@/eventbus"
  var debounce = require("debounce")

  export default {
    name: "help-structures",
    data: function() {
      return {
        structures: [],
        foundStructures: [],
        searchQuery: "",
        isLoading: false,
      }
    },
    props : ["screenWidth"],
    created() {
      this.$store.dispatch("pushPage", "quest-structures-list")
      if (!(["quest-structures", "quest-mail", "quest-mail-add"]).includes(this.$store.getters.previousPage)) {
        this.$router.replace({name: "home"})
      } else {
        this.$store.commit("setBackLogic", () => {
          this.$store.dispatch("setQuestionnaireState", "structures")
        })
      }
    },
    mounted() {
      this.isLoading = true
      api.getStructuresList().then(response => {
        if (response.status == 200) {
          this.structures = response.data.items
          this.searchStructure()
        } else {
          EventBus.$emit("responseErrorMessage")
        }
      }).catch(() => {
        EventBus.$emit("connectionErrorMessage")
      }).finally(() => {
        this.isLoading = false
      })
    },
    methods: {
      searchStructure() {
        this.isLoading = true
        this.foundStructures = this.structures.filter(this.matchesQuery)
        for (var structure of this.foundStructures) {
          structure.expires = this.computeExpirationDate(structure)
        }
        console.log(this.foundStructures)
        this.isLoading = false
      },
      debounceInput: debounce(function() { this.searchStructure() }, 500),
      selectStructure(structIdx) {
        const target = this.foundStructures[structIdx]
        var toBeAdded = true
        for (var other of this.$store.state.session.questStructures) {
          if (target.id === other.id) {
            toBeAdded = false
            break
          }
        }
        if (toBeAdded) {
          const strData = { ...target, isDisabled: true }
          this.$store.state.session.questStructures.push(strData)
        }
        this.$store.dispatch("setQuestionnaireState", "structures")
      },
      matchesQuery(structure) {
        const query = this.searchQuery.toLowerCase()
        return (
          structure.name.toLowerCase().includes(query) ||
          structure.address.toLowerCase().includes(query) ||
          structure.email.includes(query) ||
          structure.phone.includes(query)
        )
      },
      computeExpirationDate(structure) {
        const expires = new Date()
        expires.setDate(expires.getDate() + structure.form_validity_days - 1)
        return expires
      },
      searchDuplicatedStructures(sourceList, target) {
        return sourceList.findIndex(elem => elem.name == target.name)
      }
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-5-desktop">
          <div class="block">
            <h1 class="title has-text-centered is-3">
              {{ $t("title") }}
            </h1>
          </div>
          <div class="block">
            <div class="field mb-0" v-if="screenWidth >1024">
              <div class="control has-icons-right">
                <input class="input is-medium" type="text" placeholder="Cerca struttura" v-model="searchQuery" v-on:input="debounceInput">
                <span class="icon is-small is-right">
                  <i class="fas fa-search"></i>
                </span>
              </div>
            </div>
            <div class="field has-addons mb-0" v-else>
              <div class="control is-expanded">
                <input class="input is-medium" type="text" placeholder="Cerca struttura" v-model="searchQuery">
              </div>
              <div class="control" v-if="screenWidth < 1024">
                <button class="button is-info is-medium" v-on:click="searchStructure">
                  <span class="icon is-medium">
                    <i class="fas fa-search"></i>
                  </span>
                  <span v-if="screenWidth > 768 ">
                    {{ $t("search") }}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div class="block">
            <div
              class="box mb-0 my-bordered p-4"
              v-for="(structure, index) in foundStructures"
              :key="index"
              :class="{'is-clickable': !structure.isDisabled}"
              @click="selectStructure(index)"
            >
              <p class="mb-2 is-size-4"> {{ structure.name }} </p>
              <p>{{ structure.address }}</p>
              <p>{{ structure.email }}</p>
              <p>{{ structure.phone }}</p>
              <p>{{ $t("validUntil") }} <strong>{{ structure.expires.toLocaleDateString("it-IT") }}</strong></p>
            </div>
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "it": {
    "title": "Strutture",
    "validUntil": "Questionario valido fino al:"
  }
}
</i18n>

<style>
  .my-bigbutton{
    padding: 100px !important;
    width: 100%;
  }

  .my-bigbutton > img {
    margin-right:20px !important;
  }
</style>
