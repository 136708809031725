<script>
  export default {
    name: "quest-question",
    data: function(){
      return {        
        questText: null,
        questOptions: null,
        questIsMulti: null,
        questIsOptional: null
      }
    },
    computed: {
    },
    created() {
      this.$store.dispatch("pushPage", "quest-question")

      if (!(["quest-load-questions", "quest-summary"]).includes(this.$store.getters.previousPage)) {
        this.$router.replace({name: "home"})
      } else {
        this.$store.dispatch("setBackLogic", () => {
          if (this.$store.state.session.questCurrentIdx == 0) {

            this.$store.dispatch("setQuestionnaireState", "mails")
          } else {
            this.$store.dispatch("backToPreviousQuestion")
            this.$store.dispatch("enableBackButton")
            this.showCurrentQuestion()
          }
        })
      }
    },
    mounted() {
      this.showCurrentQuestion()
    },
    methods: {
      showCurrentQuestion() { // select current question data
        // is this the last question ?
        if (this.$store.state.session.questCurrentIdx === null) { // jump to questionnaire summary
            this.$store.dispatch("setQuestionnaireState", "summary")
        } else {
          var question = this.$store.state.session.questQuestions[this.$store.state.session.questCurrentIdx]
          this.questText = question.prompt.it
          this.questOptions = question.answer.options
          this.questIsOptional = question.answer.multiple
          this.questIsMulti = question.answer.nullable
          
        }
      },
      selectOption(optionKey) {
        this.$store.dispatch("setQuestionAnswer", optionKey) // update state
        this.$store.dispatch("jumpToNextQuestion") // select new question and ...
        this.showCurrentQuestion() // show the new current question
      }
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-5-desktop">
          
          <div class="container block">
            <div class="block">
              <p class="heading" v-if="questIsOptional"> Opzionale </p>

              <p class="title is-3 has-text-weight-light">
                {{ questText }}
              </p>
            </div>
            <div class="block">
              <div class="columns is-variable is-multiline">
                <div class="column is-half " v-for="(option, index) in questOptions"
                                            v-bind:key="index"
                                            v-bind:title="option.help_text"
                                            v-on:click="selectOption(option.key)">
                  <div class="button is-large is-fullwidth box my-primary-button">
                    {{ option.label.it }}
                  </div>
                </div>

                <div class="column is-12" v-if="questIsOptional">
                  <button class="button is-fullwidth box p-5" v-on:click="selectOption(null)">
                    {{ $t("optionalQuestionButton") }}
                  </button>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div> 
  </div>
</template>

<i18n>
{
  "it": {
    "question": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    "option": "Opzione",
    "yes": "Si",
    "no": "No",
    "continue": "Continua",
    "optionalQuestionButton": "Non voglio rispondere"
  }
}
</i18n>

<style>

</style>