<script>
  import QuestStructures from "@/components/QuestStructures"
  import QuestStructuresList from "@/components/QuestStructuresList"
  import QuestStructuresConsents from "@/components/QuestStructuresConsents"
  import QuestMail from "@/components/QuestMail"
  import QuestMailAdd from "@/components/QuestMailAdd"
  import QuestLoadQuestions from "@/components/QuestLoadQuestions"
  import QuestQuestion from "@/components/QuestQuestion"
  import QuestSummary from "@/components/QuestSummary"
  import QuestSubmission from "@/components/QuestSubmission"
  import QuestResult from "@/components/QuestResult"

  export default {
    name: "questionnaire",
    data: function() {
      return {
      }
    },
    props : ["screenWidth"],
    components: {
      QuestStructures,
      QuestStructuresList,
      QuestStructuresConsents,
      QuestMailAdd,
      QuestMail,
      QuestLoadQuestions,
      QuestQuestion,
      QuestSummary,
      QuestSubmission,
      QuestResult
    },
    computed: {
      currentState() {
        return this.$store.state.session.questState
      }
    },
    created() {
      if (this.currentState == "") {
        this.$router.replace({name: "home"})
      }

    },
    methods: {
    }
  }

</script>

<template>
  <div class="my-is-under-navbar">
    <!-- Structures selection -->
    <QuestStructures v-bind:screenWidth="screenWidth" v-if="currentState == 'structures'"/>
    <!-- Structures listing -->
    <QuestStructuresList v-bind:screenWidth="screenWidth" v-if="currentState == 'structures_list'"/>
    <!-- Structures consents selection -->
    <QuestStructuresConsents v-bind:screenWidth="screenWidth" v-if="currentState == 'structures_consents'"/>
    <!-- Mail Add selection -->
    <QuestMailAdd v-bind:screenWidth="screenWidth" v-if="currentState == 'mail_add'"/>
    <!-- Mail selection -->
    <QuestMail v-bind:screenWidth="screenWidth" v-if="currentState == 'mails'"/>
    <!-- Load questions -->
    <QuestLoadQuestions v-bind:screenWidth="screenWidth" v-if="currentState == 'load_questions'"/>
    <!-- Questions -->
    <QuestQuestion v-bind:screenWidth="screenWidth" v-if="currentState == 'question'"/>
    <!-- Summary -->
    <QuestSummary v-bind:screenWidth="screenWidth" v-if="currentState == 'summary'"/>
    <!-- Submission -->
    <QuestSubmission v-bind:screenWidth="screenWidth" v-if="currentState == 'submission'"/>
    <!-- Result -->
    <QuestResult v-bind:screenWidth="screenWidth" v-if="currentState == 'result'"/>
  </div>
</template>
