<script>
  import EventBus from "@/eventbus"
  import api from "@/api"

  export default {
    name: "quest-load-questions",
    created() {
      this.$store.dispatch("pushPage", "quest-load-questions")
      if (!(["quest-mail", "quest-result", "mail-activation"]).includes(this.$store.getters.previousPage)) {
        this.$router.replace({name: "home"})
      } else {
        this.$store.dispatch("disableBackButton")
        
        api.getQuestionnaire(this.$store.state.session.questStructures.map(elem => elem.id)).then(response => {
          if (response.status == 200) {
            this.$store.dispatch("setQuestQuestions", [response.data.model, response.data.questions])
            this.$store.dispatch("jumpToNextQuestion")
            this.$store.dispatch("setQuestionnaireState", "question")
          } else {
            EventBus.$emit("errorMessage", "Impossibile reperire le domande del questionario")
            this.$router.replace({name: "home"})
          }
        })
        .catch(() => {
          EventBus.$emit("errorMessage", "Impossibile reperire le domande del questionario")
          this.$router.replace({name: "home"})
        })
      }
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-4-desktop is-6-tablet">
          <div class="box is-flex is-flex-direction-column is-align-items-center section">
            <div class="block has-text-centered">
              <h1 class="title is-3">
                {{ $t("loadingTitle") }}
              </h1>
              <h2 class="subtitle is-5">
                {{ $t("loadingSubtitle") }}
              </h2>
            </div>
            <div class="loader is-loading my-loader"></div>
          </div>
        </div>
      </div>  
    </div>
  </div> 
</template>

<i18n>
{
  "it": {
    "loadingTitle": "Caricamento domande",
    "loadingSubtitle": "L'operazione dovrebbe richiedere qualche secondo"
  }
}
</i18n>

<style>

</style>