<script>
  import api from "@/api"
  import EventBus from "@/eventbus"
  export default {
    name: "quest-mail",
    data: function() {
      return {
        isLoading: false,
        withErrors: false,
        mails: []
      }
    },
    computed: {
    },
    created() {
      this.$store.dispatch("pushPage", "quest-mail")
      if (!(["quest-structures-auth", "quest-mail-add", "quest-question"]).includes(this.$store.getters.previousPage)) {
        this.$router.replace({name: "home"})
      } else {
        this.$store.dispatch("setBackLogic", () => {
          this.$store.dispatch("setQuestionnaireState", "structures_consents")
        })
      }

      this.isLoading = true
      api.getMails().then((response) => {
        this.mails = response.data.addresses
      }).catch(() => {
        EventBus.$emit("errorMessage", "Impossibile accedere alle e-mail associate")
        this.$router.replace({name: "home"})
      }).finally(() => {
        this.isLoading = false
      })
    },
    methods: {
      addMail() {
        this.$store.dispatch("setQuestionnaireState", "mail_add")
      },
      submitSelection(address) {
        this.$store.dispatch("submitQuestMail", address)
        this.$store.dispatch("setQuestionnaireState", "load_questions")
      }
    }
  }

</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section">
      <div class="columns is-centered">
        <div class="column is-5-desktop" v-if="!isLoading">
          <div class="block">
            <label class="title is-3">
              {{ $t("title") }}
            </label>
          </div>
          <div class="block">
            <article class="message is-info mb-1">
              <div class="message-body">
                {{ $t("info") }}
              </div>
            </article>
          </div>
          <div class="block">
            <div class="button is-large is-fullwidth box my-primary-button"
              style="padding:1.5em !important"
              v-for="(mail, index) in mails"
              v-bind:key="index"
              v-on:click="submitSelection(mail)">
              {{ mail }}
            </div>
          </div>
          <div class="block">
            <button class="button is-medium is-fullwidth my-secondary-button" v-on:click="addMail">
              {{ $t("addMail") }}
            </button>
          </div>
        </div>
        <div class="column is-4-desktop" v-else>
          <div class="box is-flex is-flex-direction-column is-align-items-center section">
            <div class="block has-text-centered">
              <h1 class="title is-3">
                {{ $t("loadingTitle") }}
              </h1>
              <h2 class="subtitle is-5">
                {{ $t("loadingSubtitle") }}
              </h2>
            </div>
            <div class="loader is-loading my-loader"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "it": {
    "title": "Scegli una e-mail",
    "info": "Quale indirizzo mail vuoi utilizzare? Clicca su quello che desideri oppure aggiungi una nuova e-mail.",
    "addMail": "Nuova e-mail",
    "loadingTitle": "Caricamento e-mail",
    "loadingSubtitle": "L'operazione dovrebbe richiedere qualche istante"
  }
}
</i18n>

<style>

</style>
